
import { defineComponent, onMounted, ref } from "vue";
import ClientsTable from "@/components/widgets/sistena/ClientsTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import firebaseAuth from "firebase";

export default defineComponent({
  name: "account-overview",
  props:{
    model: String
  },
  
  setup() {
    const listClients = ref([]);
    const listModels = ref([]); 
    const listDevices = ref([]);
    let macDevice
    let wifiModuleDevice
    let distributorName
    let clientName
    let clientEmail
    let selectedClients
    let selectedClient
    let selectedModel
    const fetchClients = async () => {
      await ApiService.setHeader();
      const models = await ApiService.get("clients");
      listClients.value = models.data.clients;
      console.log(listClients.value);
      
    };
    const fetchModels = async () => {
      await ApiService.setHeader();
      const models = await ApiService.get("models");
      listModels.value = models.data;
      console.log(listModels.value);
      
    };
    const fetchDevices = async () => {
      await ApiService.setHeader();
      const devices = await ApiService.get("devices");
      listDevices.value = devices.data.devices;
      console.log(listDevices.value);
      
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Account"]);
    });
    return {
      listClients,
      listModels,
      listDevices,
      macDevice,
      wifiModuleDevice,
      distributorName,
      clientName,
      clientEmail,
      selectedClients,
      selectedClient,
      selectedModel,
      fetchModels,
      fetchClients,
      fetchDevices,
    };
  },
  created() {
    this.fetchModels();
    this.fetchClients();
    this.fetchDevices();
  },
  methods: {
   async saveChangesDevice() {
      // Handle form submission
      // You can access the form data in this.distributorName and this.selectedClients
      console.log('Distributor Name:', this.macDevice);
      console.log('Selected Clients:', this.selectedModel);
      console.log('Wifi Module:', this.wifiModuleDevice);
      const apiDataDev = {
              MAC: this.macDevice,
              wifiModule:this.wifiModuleDevice,
              device: this.selectedModel.model
            };
            const datos = await ApiService.post("admin/register/device", apiDataDev)
            this.macDevice="",
            this.selectedModel="",
            this.wifiModuleDevice=""
            this.$forceUpdate();
            console.log(datos)
    },
    saveChangesDistributor() {
      // Handle form submission
      // You can access the form data in this.distributorName and this.selectedClients
      console.log('Distributor Name:', this.distributorName);
      console.log('Selected Clients:', this.selectedClients);
    },
    async saveChangesClient() {
  
      await firebaseAuth.auth().createUserWithEmailAndPassword(this.clientEmail, "Sistena01")
        .then(async (userCredential) => {
          // Signed in 
          const user = userCredential.user;
          if (user) {
             const apiData = {
              name: this.clientName,
              email:this.clientEmail,
              firebaseUid: user.uid
            };
            const datos = await ApiService.post("clients/", apiData)
            console.log(datos)
            this.clientEmail = "";
            this.clientName = "";
            this.$forceUpdate();
          } else {
            console.error('User creation failed: user is null');
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error('Error creating user:', errorCode, errorMessage);
        });
    },
  },
});
